import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Typography from "@material-ui/core/Typography";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionContact() {
  const classes = useStyles();

  return (
    <div className={classes.section} style={{ marginTop: "-32px" }}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Contact info</h2>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10} className={classes.marginAuto}>
            <Typography variant="H6" color="initial">
              Email: samuelju2017@gmail.com
            </Typography>
            <br />
            <Typography variant="H6" color="initial">
              Mobile: +61 431 996 883
            </Typography>
            <br />
            <Typography variant="H6" color="initial">
              Area: Northern Beaches, Syndey, NSW, Australia
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
