import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { purple, red } from "@material-ui/core/colors";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const classes = useStyles();
  React.useEffect(() => {
    return function cleanup() {};
  });

  return (
    <div className={classes.sections} style={{ marginTop: "-96px" }}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Skill Sets</h2>
        </div>

        <div id="progress">
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={5}>
              <div className={classes.title}>
                <h3>Skill Level Evaluation</h3>
              </div>
              <span>Java / Springboot / Microservices</span>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={75}
              />

              <span>MySQL(JPA) / Mongodb(Spring Data) / Redis</span>
              <CustomLinearProgress
                variant="determinate"
                color="info"
                value={70}
              />
              <span>API design - Restful / GraphQL</span>
              <CustomLinearProgress
                variant="determinate"
                color="gray"
                value={75}
              />

              <span>Github / Jenkins</span>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={60}
              />
              <span>Docker</span>
              <CustomLinearProgress
                variant="determinate"
                color="primary"
                value={70}
              />

              <span>AWS - (Certified Solutions Architect Associate)</span>
              <CustomLinearProgress
                variant="determinate"
                color="warning"
                value={70}
              />

              <span>TDD - Junit5 / Mockito</span>
              <CustomLinearProgress
                variant="determinate"
                color="gray"
                value={65}
              />
              <span>React / NodeJs</span>
              <CustomLinearProgress
                variant="determinate"
                color="info"
                value={55}
              />

              <span>
                Documentation (MS office / Xmind / Typora / ProcessOn)
              </span>
              <CustomLinearProgress
                variant="determinate"
                color="warning"
                value={85}
              />

              <span>Multi-Languages (English / Mandarin / Cantonese)</span>
              <CustomLinearProgress
                variant="determinate"
                color="info"
                value={90}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}></GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div>
                <div className={classes.title}>
                  {/* <h3>Skill Evaluation</h3> */}
                </div>
                <br />
                <CustomTabs
                  headerColor="info"
                  tabs={[
                    {
                      tabName: "Working Experience",
                      // tabIcon: Face,
                      tabContent: (
                        <>
                          <h6>
                            2021 ~ 2022 : Java Developer{" "}
                            <a
                              href="https://www.itnews.com.au/news/suncorp-readies-first-rollout-of-new-cape-pricing-engine-568375"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (Suncorp project)
                            </a>{" "}
                          </h6>
                          <p
                            className={classes.textCenter}
                            style={{ paddingLeft: "16px" }}
                          >
                            Contractor role working on Suncorp’s internal
                            pricing simulation system with Java and AWS.
                          </p>
                          <h6>
                            2021 : Java API Development{" "}
                            <a
                              href="https://thebreak.org.au/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (The Break Inc)
                            </a>
                          </h6>
                          <p
                            className={classes.textCenter}
                            style={{ paddingLeft: "16px" }}
                          >
                            Java API booking system for a charity company. Using
                            spring cloud microservices and Docker Jenkins CI/CD
                            integration.
                          </p>
                          <h6>
                            2016 ~ 2020 : Fit-For-Use Test Engineer{" "}
                            <a
                              href="https://tempo.org/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (Tempo Aust, Sydney)
                            </a>
                          </h6>
                          <p
                            className={classes.textCenter}
                            style={{ paddingLeft: "16px" }}
                          >
                            Test consumer electronic products such as Android
                            tablets, bluetooth speakers etc which are mainly
                            sold in ALDI, JB-HiFi, Big-W to name a few.
                          </p>

                          <h6>
                            2011 ~ 2015 : Technical Writer and Graphic Designer{" "}
                            <a
                              href="https://www.orbissystems.eu/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              (Orbissystems.eu)
                            </a>
                          </h6>
                          <p
                            className={classes.textCenter}
                            style={{ paddingLeft: "16px" }}
                          >
                            Write all kinds of technical documents for automated
                            test systems that were designed for Nokia, Ericsson,
                            Huawei etc.
                          </p>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
