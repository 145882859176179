import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import sample1 from "assets/img/sample1.jpg";
import usermanual from "assets/img/usermanual.jpg";
import tempo from "assets/img/tempo.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionMyworks() {
  const classes = useStyles();

  return (
    <div className={classes.section} style={{ marginTop: "-32px" }}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Previous works(Technical writer)</h2>
          <p>
            Only to show a few samples. These works are not related to
            development, but they show I can learn things quickly and can get
            tasks done in a timely and professional manner.
            <br />
          </p>
        </div>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            className={classes.marginAuto}
            style={{ padding: "24px" }}
          >
            <Card className={classes.root}>
              <CardActionArea>
                <a
                  href="https://bookingstorage.s3.ap-southeast-2.amazonaws.com/Tempo_Aust_Promo.mp4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CardMedia
                    component="img"
                    alt="tempo video"
                    height="280"
                    image={tempo}
                    title="Contemplative Reptile"
                    // src="https://www.youtube.com/watch?v=aYSDYRXLQFY&list=PLWxSJr7LCl862wtxLc5o3LLsh3nK4S8gQ"
                  />
                </a>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Tempo Aust TV show promotion video -
                    2017(PS/AI/AfterEffects) - 03:55
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <a
                    href="https://bookingstorage.s3.ap-southeast-2.amazonaws.com/Tempo_Aust_Promo.mp4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                </Button>
              </CardActions>
            </Card>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            className={classes.marginAuto}
            style={{ padding: "24px" }}
          >
            <Card className={classes.root}>
              <CardActionArea>
                <a
                  href="https://bookingstorage.s3.ap-southeast-2.amazonaws.com/4-Wire+Villa+System_draft.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="280"
                    image={usermanual}
                    title="Contemplative Reptile"
                  />
                </a>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    User manual for video doorbell consumer products - 2009
                    (Illustrator/Indesign)
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <a
                    href="https://bookingstorage.s3.ap-southeast-2.amazonaws.com/4-Wire+Villa+System_draft.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                </Button>
              </CardActions>
            </Card>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={4}
            className={classes.marginAuto}
            style={{ padding: "24px" }}
          >
            <Card className={classes.root}>
              <CardActionArea>
                <a
                  href="https://bookingstorage.s3.ap-southeast-2.amazonaws.com/MMI+sample.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="280"
                    image={sample1}
                    title="Contemplative Reptile"
                  />
                </a>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Technical documents for MMI automatic test system - 2012
                    (Solidworks/Microsoft Word)
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  <a
                    href="https://bookingstorage.s3.ap-southeast-2.amazonaws.com/MMI+sample.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                </Button>
              </CardActions>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
