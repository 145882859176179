import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

import bookingDiagram from "assets/img/bookingstructure.jpg";
import graphql from "assets/img/graphql.jpg";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>Projects</h2>
        </div>
        <div className={classes.space10} />
        <div id="navigation-pills">
          <div className={classes.title}>
            <h3>
              <small>Commercial Project - Pricing Simulation System</small>
            </h3>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <div>
                <p>
                  Commercial project for one of the top players in
                  insurance/banking industry, heavily use Java microservice and
                  AWS cloud (Lambda, S3, SQS).
                </p>
                <p>
                  Development environment: JIRA, Confluence page, Bitbucket,
                  Jenkins, Docker, OpenShift. Development tech stacks: Spring
                  boot, Junit/Mockito, Nodejs, AWS serverless.
                </p>
                <p>
                  My role is to handle the user email notification function for
                  different process stages. In the main service (Spring boot),
                  send out stage messages to AWS SQS; and in the email service
                  (Serverless Lambda with Nodejs) consume the messages and send
                  out the emails to users.
                </p>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.title}>
            <h3>
              <small>
                Commercial Project - Roombooking Microservices Backend
              </small>
            </h3>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <div>
                <p>
                  This project is made for a charity company in Melbourne who
                  provide free events and activities to people in need. It
                  &apos;s using microservices architecture and deploy with
                  docker containers.
                </p>
                <p>
                  Stacks for the API including Java11 SpringBoot, SpringCloud
                  Gateway, KeyCloak IAM, Consul service discover and config,
                  Kafka messaging, Braintree payment, Spring data mongo, AWS S3,
                  AWS EC2 linux, Git and Jenkins CICD with Docker containers.
                  Next step will be making it Highly Available with distributed
                  and Kubernetes.
                  {/* <a
                    href="https://github.com/sydneyfullstack/roombooking/tree/java11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    see the Github repo here.
                  </a> */}
                </p>

                <p>
                  Swagger API docs:
                  <br />
                  <a
                    href="http://nobrainer.link:8081/api/v1/app/swagger.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Main app API.
                  </a>
                  {"  "}
                  <a
                    href="http://nobrainer.link:8084/api/v1/payment/swagger.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Payment service API.
                  </a>
                  {"  "}
                  <a
                    href="http://nobrainer.link:8085/api/v1/email/swagger.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email service API.
                  </a>
                  {"  "}
                  <a
                    href="http://nobrainer.link:8086/api/v1/dicts/swagger.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dictionary service API.
                  </a>
                  {"  "}
                  <a
                    href="http://nobrainer.link:8087/api/v1/file/swagger.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    File service API.
                  </a>
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  src={bookingDiagram}
                  alt="roombooking diagram"
                  width="95%"
                />
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.title}>
            <h3>
              <small>Practice Project - React GraphQL Tiny Blog</small>
            </h3>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <div>
                <p>
                  This tiny practise project was made in 2019 when I started to
                  learn React Nodejs full stack development. It was deployed in
                  Netlify, Heroku and MongoDB Atlas at the beginning, and I have
                  just moved it to AWS using docker.
                </p>
                <p>
                  For the frontend it uses React + Semantic UI; the backend is a
                  Nodejs GraphQL, with express, Apollo and Mongodb(mongoose).
                  <br />
                  <a
                    href="http://blog.nobrainer.link/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to try the app.
                  </a>{" "}
                  <br />
                  <a
                    href="http://blog.nobrainer.link:4040/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here for GraphiQL playground.
                  </a>{" "}
                </p>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img src={graphql} alt="roombooking diagram" width="90%" />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
