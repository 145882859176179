/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, GitHub, Devices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="my projects"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Devices}
          dropdownList={[
            // <Link to="/" className={classes.dropdownLink}>
            //   <i className={classes.socialIcons + " fab fa-comments"} />
            //   React GraphQL tiny blog
            // </Link>,

            <a
              href="http://nobrainer.link:8081/api/v1/app/swagger.html"
              target="_blank"
              className={classes.dropdownLink}
            >
              Spring booking Restful API
            </a>,

            <a
              href="http://blog.nobrainer.link/"
              target="_blank"
              className={classes.dropdownLink}
            >
              React GraphQL tiny blog
            </a>,
            <a
              href="http://blog.nobrainer.link:4040"
              target="_blank"
              className={classes.dropdownLink}
            >
              GraphQL blog api
            </a>,
          ]}
        />
      </ListItem>

      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="github repository"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={GitHub}
          dropdownList={[
            <a
              href="https://github.com/sydneyfullstack/roombooking/tree/java11"
              target="_blank"
              className={classes.dropdownLink}
            >
              Spring booking api Repo
            </a>,

            <a
              href="https://github.com/sydneyfullstack/gqls-blog"
              target="_blank"
              className={classes.dropdownLink}
            >
              GraphQL blog server
            </a>,
            <a
              href="https://github.com/sydneyfullstack/gql-blog-client"
              target="_blank"
              className={classes.dropdownLink}
            >
              GraphQL blog frontend
            </a>,
          ]}
        />
      </ListItem> */}

      <ListItem className={classes.listItem}>
        <Tooltip
          id="aws"
          title="AWS certified Solutions Architect Associate"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.credly.com/badges/ae10dabe-4e03-48f1-ba21-b769434eb3ba/public_url"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-aws"} />
            my badge
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
